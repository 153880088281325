import React, { useState } from 'react'
import { YellowLine } from '../svg'

const ext_colors = [
  {
    name: 'White',
    hex: '#FFFFFF'
  },
  {
    name: 'Silver',
    hex: '#E9E9E9'
  },
  {
    name: 'Gray',
    hex: '#606060'
  },
  {
    name: 'Red',
    hex: '#DE1B00'
  },
  {
    name: 'Blue',
    hex: '#1141E8'
  },
  {
    name: 'Yellow',
    hex: '#FFF625'
  },
  {
    name: 'Green',
    hex: '#2BA300'
  },
  {
    name: 'other',
    img: '/img/gradient_img.svg'
  }
]

const int_colors = [
  {
    name: 'Brown',
    hex: '#352821'
  },
  {
    name: 'Garnet',
    hex: '#551A15'
  },
  {
    name: 'Modena',
    hex: '#AC6C42'
  },
  {
    name: 'Lotus White',
    hex: '#CAB9A3'
  },
  {
    name: 'Ultramarine',
    hex: '#4B4B59'
  },
  {
    name: 'Other',
    img: '/img/gradient_img.svg'
  }
]

export default function StepColorExterior({ inputs, validations, setValidations, handleInputChange, setCurrentStep }) {

  const [otherColor, setOtherColor] = useState("");
  const [otherInteriorColor, setOtherInteriorColor] = useState("");

  const checkValidations = () => {
    if (!inputs.ext_color) {
      setValidations(prev => ({ ...prev, ext_color: true }))
      return
    }
    if (!inputs.int_color) {
      setValidations(prev => ({ ...prev, int_color: true }))
      return
    }
    setCurrentStep(prev => (prev + 1))
  }

  return (
    <div>
      <h2 className='sub-heading mt-40 mb-0'>
        We found your car! <br className='d-lg-block' />
        Let’s confirm a few details.
      </h2>
      <div className='car-functionality mt-40'>
        <YellowLine />
        <p className='mb-0 ms-1 text-uppercase d-inline'>
          What <span className='color-purplr'>
            color </span> is your vehicle's
          <span className='color-purplr'> exterior?
          </span>
        </p>
      </div>
      <div className='color_grid mt-20'>
        {
          inputs?.color &&
          <div>
            <div
              className="color-palettes d-flex flex-column align-items-center text-capitalize"
              onClick={() => handleInputChange({ target: { name: 'ext_color', value: inputs.color } })}
            >
              <div className={`color-palettes-border${inputs.ext_color === inputs.color ? ' active' : ''}`}>
                <div className='color-box' style={{ background: inputs.color }}></div>
              </div>
              {inputs.color}
            </div>
          </div>
        }
        {
          ext_colors.map((i, index) =>
            <div key={index}>
              <div
                className='color-palettes d-flex flex-column align-items-center text-center'
                onClick={() => {
                  if (ext_colors.length - 1 == index) {
                    handleInputChange({ target: { name: 'ext_color', value: otherColor } })
                  } else {
                    handleInputChange({ target: { name: 'ext_color', value: i.name } })
                  }
                }}
              >
                {
                  ext_colors.length - 1 == index ?
                    <div className={`color-palettes-border ${inputs.ext_color === i.name ? 'active' : ''}`}>
                      <input className='color-box color_picker' type="color" id="color-input" value={otherColor} onChange={(e) => setOtherColor(e.target.value)}></input>
                    </div>
                    :
                    <div className={`color-palettes-border ${inputs.ext_color === i.name ? 'active' : ''}`}>
                      <div className='color-box' style={i.img ? { backgroundImage: `url('/assets/img/gradient_img.svg')` } : { background: i.hex }}></div>
                    </div>
                }
                {i.name}
              </div>
            </div>
          )
        }
      </div>
      {validations.ext_color && <div className='err_msg'>Please select exterior</div>}
      <div className='col-12 mt-40'>
        <div className='car-functionality'>
          <YellowLine />
          <p className='mb-0 ms-1 text-uppercase d-inline'>
            What <span className='color-purplr'> color </span>is your vehicle's <span className='color-purplr'> interior?</span>
          </p>
        </div>
        <div className='color_grid mt-20'>
          {
            int_colors.map((i, index) =>
              <div key={index}>
                <div
                  className="color-palettes d-flex flex-column align-items-center text-center"
                  onClick={() => {
                    if (int_colors.length - 1 == index) {
                      handleInputChange({ target: { name: 'int_color', value: otherInteriorColor } })
                    } else {
                      handleInputChange({ target: { name: 'int_color', value: i.name } })
                    }
                  }
                  }
                >
                  {
                    int_colors.length - 1 == index ?
                      <div className={`color-palettes-border ${inputs.int_colors === i.name ? 'active' : ''}`}>
                        <input className='color-box color_picker' type="color" id="color-input" value={otherInteriorColor} onChange={(e) => setOtherInteriorColor(e.target.value)}></input>
                      </div>
                      :
                      <div className={`color-palettes-border ${inputs.int_color === i.name ? 'active' : ''}`}>
                        <div className='color-box' style={i.img ? { backgroundImage: `url('/assets/img/gradient_img.svg')` } : { background: i.hex }}></div>
                      </div>
                  }

                  {i.name}
                </div>
              </div>
            )
          }
        </div>
        {validations.int_color && <div className='err_msg'>Please select interior</div>}
      </div>
      <div className='mt-40'>
        <button className='gray-btn border-0 btnyellow' onClick={checkValidations}>
          <span className='wx-170'>Next</span>
        </button>
      </div>
    </div>
  )
}
