import React, { useEffect, useState } from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
import { CancelCircle, File, Handshake, Paid, TrueCircle } from '../components/svg'
import Animation from '../components/Animation'
import axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom';
import { ADMIN_ROOT_URL, ROOT_URL } from '../utils/rootUrl'
import { MessageBox } from '../components/MessageBox'

export default function TrueValue() {

    const PAGE_SIZE = 6;

    const location = useLocation();
    const inputs = location?.state || {};

    const [loading, setLoading] = useState(false);
    const [dealerLoading, setDealerLoading] = useState(false);
    const [carValuation, setCarValuation] = useState(0);
    const [dealerData, setDealerData] = useState([]);
    const [visibleCount, setVisibleCount] = useState(PAGE_SIZE);

    const handleShowMore = () => {
        setVisibleCount((prev) => prev + PAGE_SIZE);
    };

    const navigate = useNavigate();

    const getCarValuation = async () => {
        const params = {};
        for (const key in inputs) {
            if (key === "features" || key === "condition") {
                params[key] = JSON.stringify(inputs[key]);
            } else {
                params[key] = inputs[key];
            }
        }
        params.style = inputs.ucgsubsegment
        params.drive = inputs.drivetype
        params.fuel_type = inputs.fueltype
        params.vin_number = inputs.vin_number || inputs.lic_number
        try {
            setLoading(true)
            const res = await axios.post(`${ROOT_URL}/valuationForm`, params);
            setCarValuation(res.data?.data?.carValue || 0);
        } catch (err) {
            console.log(err);
            MessageBox("error", err?.response?.data?.msg || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getCarValuation()
    }, [])


    const handleAcceptOffer = () => {
        if (loading) return
        if (localStorage.getItem("userid")) {
            insertCar();
        } else {
            checkUser();
        }
    };

    const insertCar = async () => {
        try {
            setLoading(true);
            const res = await axios.post(`${ROOT_URL}/handleUpdatForm`, {
                userid: localStorage.getItem("userid"),
                carID: inputs.carID,
            });
            localStorage.removeItem("offer-details");
            localStorage.removeItem("current-step");
            navigate("/user-details");
        } catch (err) {
            console.log(err);
            MessageBox("error", err?.response?.data?.msg || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    const checkUser = async () => {
        try {
            setLoading(true);
            const res = await axios.post(`${ROOT_URL}/checkRegister`, {
                email: inputs.email,
            });
            localStorage.removeItem("offer-details");
            localStorage.removeItem("current-step");
            const carId = inputs.carID
            navigate("/" + res.data?.msg?.toLowerCase() || "/login", {
                state: { ...inputs, carId },
            });
        } catch (err) {
            console.log(err);
            MessageBox("error", err?.response?.data?.msg || "Something went wrong");
        } finally {
            setLoading(false);
        }
    };

    const handleCancelOffer = () => {
        localStorage.removeItem("offer-details");
        localStorage.removeItem("current-step");
        navigate("/");
    };

    useEffect(() => {
        fetchDealer();
    }, [])

    const fetchDealer = async () => {
        try {
            setDealerLoading(true);
            const res = await axios.get(`${ADMIN_ROOT_URL}/dealerList?zipCode=${inputs?.zip_code}`);
            setDealerData(res.data.data)
        }
        catch (error) {
            MessageBox("error", error?.response?.data?.msg || "Something went wrong");
        }
        finally {
            setDealerLoading(false);
        }
    }

    return (
        <>
            <div className='hero-section true-value-bg'>
                <div className='container'>
                    <Header className={"light-nav"} />
                    <div className='padding-block'>
                        <div className='mt-100 position-relative'>
                            <div className='row flex-column gap_365'>
                                <div className='col-lg-4'>
                                    <div className='d-flex  justify-content-between '>
                                        <div className='d-flex flex-column gap_20'>
                                            <span className='small-bas-heading color-yellow'>Your Instant Cash Offer!</span>
                                            <h1 className='main-heading color_white mb-0'>${carValuation}</h1>
                                        </div>

                                    </div>
                                </div>
                                <div className='col-12'>
                                    <div className='row justify-content-between'>
                                        <div className="col-xl-4 col-md-6">
                                            <div>
                                                <p className='mb-0 color_white opacity-75 text-capitalize'>No need to take any action - you’ll hear from the dealers below shortly.</p>
                                                <p className='mb-0 color_white opacity-75 mt-20 text-capitalize'>Offer subject to dealer verification
                                                    <br />
                                                    <span className='font_bolder color_white'>Expires 12/05/2024 10:13 PM PST.</span></p>

                                            </div>
                                        </div>
                                        <div className="col-md-5 text-end d-flex align-items-end justify-content-md-end gap-3 mt-md-0 mt-3">
                                            <button onClick={handleAcceptOffer} className='gray-btn border-0 btn-dealer text-center btn_print'>
                                                <span className='w-100'>Accept</span>
                                            </button>
                                            <button onClick={handleCancelOffer} className='gray-btn border-0 btn-dealer text-center btn_print'>
                                                <span className='w-100'>Cancel</span>
                                            </button>

                                        </div>

                                    </div>
                                </div>
                                <div className='car_value'>
                                    <img src="/assets/img/home/car_value.webp" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='section_dealer sell_bg padding-block-80'>
                <div className='container padding-block'>
                    <div className='text-center'>
                        {/* <span className='small-bas-heading color-purplr mb-0'>Your True Cash Offer!</span> */}
                        <h2 className='big-middel-heading color-dark mt-20 mb-0'>Your Connected Dealers</h2>
                        <p className='color-dark opacity-75 mb-0 mt-20'>These Dealers are ready to buy car and will reach out to you soon.</p>
                    </div>
                    {
                        loading ?
                            <div className='text-align-class'>
                                <p className='text-align-center'>Loading...</p>
                            </div> :
                            dealerData.length > 0 ?
                                <div className='row g-2 mt-60'>
                                    {
                                        dealerData.slice(0, visibleCount).map((el, index) => {
                                            return (
                                                <div className='col-lg-4 col-md-6 col-12'>
                                                    <div className='dealer_card'>
                                                        <div className='d-flex flex-column gap_30'>
                                                            <a href={el?.link} target='_blank'>
                                                                <img src="/assets/img/about/map.webp" alt="map" className='w-100' />
                                                            </a>
                                                            <div>
                                                                <div className='d-flex align-items-xxl-center'>
                                                                    <span className='border-h'></span>
                                                                    <span className='border-h m-0'></span>
                                                                    <h3 className='dealer_title mb-0 ps_5'>{el?.center_name}</h3>
                                                                </div>
                                                                <div className='d-flex align-items-center gap-3 mt-20'>
                                                                    <div className='dealer_add'>
                                                                        <span className='mb-1'>Address:</span>
                                                                        <p className='mb-0'>{el?.address}</p>
                                                                    </div>
                                                                    <div className='border-horizontal'></div>
                                                                    <div className='dealer_add'>
                                                                        <span className='mb-1'>{el?.name}</span>
                                                                        <p className='mb-0'>{el?.designation}: {el?.mobileno}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <a href={el?.website_url} target='_blank' className='gray-btn border-0 btn-dealer w-100 text-center'>
                                                                <span className='w-100'>GET IN TOUCH</span>
                                                            </a>
                                                        </div>

                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> :
                                <div className='text-align-class'>
                                    <p className='text-align-center'>Data not found</p>
                                </div>

                    }

                    {visibleCount < dealerData.length && (
                        <div className='text-center mt-3'>
                            <button onClick={handleShowMore} className='gray-btn border-0 btn-dealer w-100 text-center w-auto'>
                                <span className='w-100'>More Dealer</span>
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <div className='blog-section'>
                <div className='container padding-block'>
                    <div className='text-center'>
                        <h2 className='big-middel-heading color-dark mb-0'>What happens Next?</h2>
                        <p className='color-dark opacity-75 mb-0 mt-20'>These Dealers are ready to buy car and will reach out to you soon.</p>
                    </div>
                    <div className='row justify-content-between align-items-center mt-60'>
                        <div className='col-lg-6 padding_right mb-lg-0 mb-5'>
                            <div>
                                <img src="/assets/img/about/dealership.webp" className='w-100' alt="car dealership" />
                            </div>
                        </div>
                        <div className='col-lg-6 padding_left'>
                            <div className='d-flex flex-column gap_30'>
                                <div className='d-flex align-items-center gap_x_20'>
                                    <div className='value-card bg-purple'>
                                        <Handshake />
                                    </div>
                                    <div>
                                        <h2 className='value-title mb-xxl-3 mb-2'>Connect with Dealer</h2>
                                        <p className='card_pera mb-0'>The dealer will reach out to confirm your car’s condition.</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap_x_20'>
                                    <div className='value-card bg-yellow'>
                                        <File />
                                    </div>
                                    <div>
                                        <h2 className='value-title mb-xxl-3 mb-2'>Gather your Paperwork</h2>
                                        <p className='card_pera mb-0'>This Includes your car title and any loan documents</p>
                                    </div>
                                </div>
                                <div className='d-flex align-items-center gap_x_20'>
                                    <div className='value-card bg-green'>
                                        <Paid />
                                    </div>
                                    <div>
                                        <h2 className='value-title mb-xxl-3 mb-2'>Get Paid</h2>
                                        <p className='card_pera mb-0'>Cash out or trade in your car for a new one.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='padding-block-50'>
                <Animation />
            </div>
            <div className='deal-history padding-block-50'>
                <div className='container padding-block'>
                    <div className='text-center'>
                        <span className='small-bas-heading color-purplr mb-0'>deal deafferent</span>
                        <h2 className='big-middel-heading color-dark mt-20 mb-0'>Instant Cash Price vs Private Party</h2>
                    </div>
                    <div className='mt-60 table-responsive'>
                        <table className='table deal_data'>
                            <thead>
                                <tr>
                                    <th></th>
                                    <th className='color-yellow'>Instant Cash Price</th>
                                    <th className='color-border'>Private Party</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Sell Time</td>
                                    <td className='color_white '>1 Day</td>
                                    <td>Up to 60 Days</td>
                                </tr>
                                <tr>
                                    <td>Potential for higher price</td>
                                    <td><CancelCircle /></td>
                                    <td><TrueCircle /></td>
                                </tr>
                                <tr>
                                    <td>Avoid price depreciation</td>
                                    <td><TrueCircle /></td>
                                    <td><CancelCircle /></td>
                                </tr>
                                <tr>
                                    <td>Potential tax saving on trade-in</td>
                                    <td><TrueCircle /></td>
                                    <td><CancelCircle /></td>
                                </tr>
                                <tr>
                                    <td>Trusted buyer</td>
                                    <td><TrueCircle /></td>
                                    <td><CancelCircle /></td>
                                </tr>
                                <tr>
                                    <td>Total transparency</td>
                                    <td><TrueCircle /></td>
                                    <td><CancelCircle /></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
